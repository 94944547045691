<template>
  <div class="flex flex-column align-items-center prestitosi-topbar">
    <div
      class="w-full flex justify-content-between align-items-center prestitosi-top-subbar"
    >
      <div
        @click="goBack"
        class="flex align-items-center cursor-pointer prestitosi-top-subbar-item"
      >
        <i class="pi pi-angle-left"></i>
        Torna indietro
      </div>
      <div>
        <span
          v-if="loggedUser.livelloLogin != 'Demo Gestionale'"
          class="prestitosi-top-subbar-item mr-4"
          @click="$router.push({ name: 'DataDrive' })"
          ><i class="pi pi-file mr-2"></i> Documenti</span
        >
        <span class="prestitosi-top-subbar-item" @click="logout"
          ><i class="pi pi-sign-out mr-2"></i> Logout</span
        >
      </div>
    </div>
    <div class="w-full flex align-items-center prestitosi-bottom-subbar">
      <span v-if="!CURRENT_URL.includes('prestitosi-automotive')" class="mr-4"
        ><img
          @click="$router.push('/')"
          src="../../assets/images/space-now.png"
          style="width: 172px; cursor: pointer"
        />
      </span>
      <span
        v-else
        class="mr-4 p-1"
        style="background: white; border-radius: 3px"
        ><img
          @click="$router.push('/')"
          src="../../assets/images/LOGO-SISTEMA.png"
          style="width: 172px; cursor: pointer"
      /></span>
      <div class="flex-grow-1 prestitosi-menubar">
        <AppTopMenu :model="model"></AppTopMenu>
      </div>
      <div>
        <InputText
          placeholder="Ricerca generale..."
          class="me-4"
          v-model="generalSearch"
          @keypress.enter="
            $router.push({ name: 'search', params: { query: generalSearch } })
          "
        ></InputText>
      </div>
      <div class="ms-auto d-flex align-items-center">
        <span class="me-2"
          >Ciao,
          <strong>{{ loggedUser.id ? loggedUser.nome : "User" }}</strong>
        </span>

        <div class="d-flex">
          <div class="grid-center">
            <Avatar
              v-if="loggedUser.imgprofilo"
              :image="loggedUser.imgprofilo"
            ></Avatar>
            <Avatar
              v-else
              :label="loggedUser.id ? loggedUser.nome.slice(0, 1) : 'U'"
            ></Avatar>
          </div>
          <Tag
            v-if="loggedUser.livelloLogin"
            :value="loggedUser.livelloLogin"
            style="border-top-left-radius: 0; border-bottom-left-radius: 0"
          ></Tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import Avatar from "primevue/avatar";
import AxiosService from "@/axiosServices/AxiosService";
import { useStore } from "vuex";
import AppTopMenu from "./AppTopMenu.vue";

export default {
  props: {
    model: Object,
  },
  created() {
    const store = useStore();

    setTimeout(() => {
      this.userVoip = store.getters["loggedUser"];
    }, 1000);
  },
  data() {
    return {
      userVoip: null,
      logoCliente:
        "https://prestitosifinance.it/wp-content/uploads/2022/09/PRESTITOSI.png",
      generalSearch: null,
      CURRENT_URL: window.location.href,
    };
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    logout: function () {
      const service = new AxiosService("Auth/Logout");
      service.create();

      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push("login");
      });
    },
    goBack() {
      this.$router.back();
    },
  },
  computed: mapGetters(["loggedUser"]),
  components: { Avatar, AppTopMenu },
};
</script>

<style>
.prestitosi-menubar .p-menuitem-icon {
  margin-right: 0 !important;
}
</style>
