import { createWebHistory, createRouter } from "vue-router";
import store from "../store";
import AppLayout from "@/views/layout/AppLayout.vue";

const CURRENT_URL = window.location.href;

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/login/Login.vue"),
      meta: {
        title: "DataRete Cloud - Accesso",
      },
    },
    {
      path: "/changepasswordrequest",
      name: "ChangePasswordRequest",
      component: () => import("../views/login/ChangePasswordRequest.vue"),
      meta: {
        title: "DataRete Cloud - Richiesta Cambio Password",
      },
    },
    {
      path: "/changepasswordperform/:code",
      name: "ChangePasswordPerform",
      component: () => import("@/views/login/ChangePasswordPerform.vue"),
      meta: {
        title: "DataRete Cloud - Realizza Cambio Password",
      },
    },
    {
      path: "/setloginlevel",
      name: "SetLoginLevel",
      component: () => import("../views/login/SetLoginLevel.vue"),
      meta: {
        title: "DataRete Cloud - Seleaziona il livello di Accesso",
      },
    },
    {
      path: "/",
      component: AppLayout,
      children: [
        {
          path: "profilo",
          name: "ProfiloUtente",
          component: () => import("@/views/ProfiloUtente.vue"),
        },
        {
          path: "/search/:query",
          name: "search",
          component: () => import("@/views/GeneralSearch.vue"),
        },
        {
          path: "/nuova-anagrafica/:idPratica",
          component: () => import("@/views/NuovaAnagrafica.vue"),
        },
        {
          path: "/uploader",
          component: () => import("@/views/Upload.vue"),
        },
        {
          path: "/uploader-contatti",
          component: () => import("@/views/UploadContatti.vue"),
        },
        {
          path: "/testtry1",
          name: "test",
          component: () => import("@/views/TestTry.vue"),
        },
        {
          path: "/testtry2",
          name: "test2",
          component: () => import("@/views/DocumentiBelli.vue"),
        },
        {
          path: "/testtry3",
          name: "test3",
          component: () => import("@/views/Testtry3.vue"),
        },
        {
          path: "",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            title: "DataRete Cloud - Dashboard",
          },
        },
        {
          path: "dashboard-due",
          name: "Dashboard-due",
          component: () => import("@/views/Dashboard_2.vue"),
          meta: {
            title: "DatRete - Dashboard 2",
          },
        },
        {
          path: "dashboard-tre",
          name: "Dashboard-tre",
          component: () => import("@/views/Dashboard_3.vue"),
          meta: {
            title: "DatRete - Dashboard 2",
          },
        },

        {
          path: "faqs",
          name: "Faqs",
          component: () => import("@/views/Faqs.vue"),
          meta: {
            title: "DataRete Cloud - Faqs",
          },
        },
        {
          path: "amministrazione",
          name: "amministrazione",
          children: [
            {
              path: "provvigioni",
              component: () =>
                import("@/views/amministrazione/Provvigioni.vue"),
            },
            {
              path: "spese",
              component: () =>
                import("@/views/amministrazione/SpeseElenco.vue"),
            },
            {
              path: "tipo-spese",
              component: () =>
                import("@/views/amministrazione/SpesePerTipo.vue"),
            },
            {
              path: "incassi",
              component: () =>
                import("@/views/amministrazione/IncassiElenco.vue"),
            },
            {
              path: "tipo-incassi",
              component: () =>
                import("@/views/amministrazione/IncassiPerTipo.vue"),
            },
            {
              path: "tipo-totali",
              component: () =>
                import("@/views/amministrazione/TotaliPerTipo.vue"),
            },
            {
              path: "scadenziario-pagamenti",
              component: () =>
                import("@/views/amministrazione/ScadenziarioPagamenti.vue"),
            },
            {
              path: "note-spesa",
              component: () => import("@/views/gestione/NoteSpesa.vue"),
            },
            {
              path: "ordini",
              component: () => import("@/views/gestione/Ordini.vue"),
            },
            {
              path: "fornitori",
              component: () => import("@/views/gestione/Fornitori.vue"),
            },
          ],
        },
        {
          path: "bacheca",
          name: "bacheca",
          children: [
            {
              path: "recruitment-visite",
              name: "RecruitmentVisite",
              component: () => import("@/views/bacheca/RecruitmentVisite.vue"),
              meta: { title: "DataRete Cloud - Recruitment Visite" },
            },
            {
              path: "recruitment-dashboard",
              name: "RecruitmentDashboard",
              component: () =>
                import("@/views/bacheca/RecruitmentDashboard.vue"),
              meta: { title: "DataRete Cloud - Recruitment Dashboard" },
            },
            {
              path: "complete-stats",
              name: "CompleteStats",
              component: () => import("@/views/bacheca/CompleteStats.vue"),
              meta: { title: "DataRete Cloud - Statistiche" },
            },
            {
              path: "circolari",
              name: "circolari",
              component: () => import("@/views/bacheca/Circolari.vue"),
            },
            {
              path: "report-filiali",
              name: "ReportFiliali",
              component: () => import("@/views/bacheca/ReportFiliali.vue"),
              meta: { title: "DataRete Cloud - Report filiali" },
            },
            {
              path: "report-agenti/:idExecutive/:nomeExecutive",
              name: "ReportAgenti",
              component: () => import("@/views/bacheca/ReportAgenti.vue"),
              meta: { title: "DataRete Cloud - Report agenti" },
            },
            {
              path: "report-top-manager",
              name: "ReportTopManager",
              component: () => import("@/views/bacheca/ReportTopManager.vue"),
              meta: { title: "DataRete Cloud - Report Top Manager" },
            },
            {
              path: "report-executive",
              name: "ReportExecutive",
              component: () => import("@/views/bacheca/ReportExecutive.vue"),
              meta: { title: "DataRete Cloud - report executive" },
            },
            {
              path: "classifica-agenti",
              name: "ClassificaAgenti",
              component: () => import("@/views/bacheca/ClassificaAgenti.vue"),
            },
            {
              path: "classifica-executive",
              name: "ClassificaExecutive",
              component: () =>
                import("@/views/bacheca/ClassificaExecutive.vue"),
            },
            {
              path: "classifica-top-manager",
              name: "ClassificaTopManager",
              component: () =>
                import("@/views/bacheca/ClassificaTopManager.vue"),
            },
            {
              path: "classifica-filiali",
              name: "ClassificaFiliali",
              component: () => import("@/views/bacheca/ClassificaFiliali.vue"),
            },
            {
              path: "statistiche",
              name: "Statistiche",
              component: () => import("@/views/bacheca/Statistiche.vue"),
              meta: {
                title: "DataRete Cloud - Statistiche",
              },
            },
            {
              path: "statistiche-produzione",
              name: "StatisticheProduzione",
              component: () =>
                import("@/views/bacheca/StatisticheProduzione.vue"),
              meta: {
                title: "DataRete Cloud - Statistiche produzione",
              },
            },
            {
              path: "dettaglio-statistiche/:periodo/:tipoStatistica/:tipoProdotto",
              name: "DettaglioStatistiche",
              component: () =>
                import("@/views/bacheca/DettaglioStatistiche.vue"),
              meta: { title: "DataRete Cloud - Dettaglio statistiche" },
            },

            {
              path: "report-produzione-executive/:periodo/:tipoProdotto",
              name: "ReportProduzioneExecutive",
              component: () =>
                import("@/views/bacheca/ReportProduzioneExecutive.vue"),
            },
          ],
        },
        {
          path: "anagrafiche",
          name: "anagrafiche",
          children: [
            {
              path: "recruitment",
              name: "recruitment",
              component: () => import("@/views/anagrafiche/Recruitment.vue"),
            },
            {
              path: "clienti",
              name: "clienti",
              component: () => import("@/views/anagrafiche/Retail.vue"),
              meta: {
                title: "DataRete Cloud - Clienti",
              },
            },
            {
              path: "lead",
              name: "lead",
              component: () => import("@/views/anagrafiche/Retail.vue"),
              meta: {
                title: "DataRete Cloud - Lead",
              },
            },
            {
              path: "lead-convertiti",
              name: "lead-convertiti",
              component: () => import("@/views/anagrafiche/Retail.vue"),
              meta: {
                title: "DataRete Cloud - Lead convertiti",
              },
            },
            {
              path: "corporate",
              name: "corporate",
              component: () => import("@/views/anagrafiche/Corporate.vue"),
              meta: {
                title: "DataRete Cloud - Corporate",
              },
            },
            {
              path: "agenda/calendario",
              redirect: {
                name: "calendario",
              },
            },
          ],
        },
        {
          path: "agenda",
          name: "agenda",
          children: [
            {
              path: "calendario",
              name: "calendario",
              component: () => import("@/views/agenda/Calendario.vue"),
              meta: {
                title: "DataRete Cloud - Calendario Eventi",
              },
            },
          ],
        },
        {
          path: "pratiche",
          name: "pratiche",
          children: [
            {
              path: "dettaglio-pratica/:idPratica",
              name: "dettaglio-pratica",
              component: () => import("@/views/pratiche/DettaglioPratica.vue"),
            },
            {
              path: "dettaglio-pratica-corporate/:idPratica",
              name: "dettaglio-pratica-corporate",
              component: () =>
                import("@/views/pratiche/DettaglioPraticaCorporate.vue"),
            },
            {
              path: "preventivatore-hub/",
              name: "preventivatore-hub",
              component: () => import("@/views/pratiche/PreventivatoreHub.vue"),
            },
            {
              path: "preventivatore/",
              name: "preventivatore",
              component: () => import("@/views/pratiche/Preventivatore.vue"),
            },
            {
              path: "preventivo-mutuo/:idAnagrafica/:idPreventivo",
              name: "preventivo-mutuo",
              component: () => import("@/views/pratiche/PreventivoMutuo.vue"),
            },
            {
              path: "preventivo-prestito/:idAnagrafica/:idPreventivo",
              name: "preventivo-prestito",
              component: () =>
                import("@/views/pratiche/PreventivoPrestito.vue"),
            },
            {
              path: "preventivo-cqs/:idAnagrafica/:idPreventivo/:idPratica/:tipoProdotto",
              name: "preventivo-cqs",
              component: () =>
                import("@/views/pratiche/PreventivoCQS&Delega.vue"),
            },

            {
              path: "preventivo-test-delega/:idAnagrafica/:idPreventivo/:idPratica/:tipoProdotto",
              name: "preventivo-test-delega",
              component: () =>
                import("@/views/pratiche/PreventivoCQS&DelegaTest.vue"),
            },

            {
              path: "preventivo-test-mutuo/:idAnagrafica/:idPreventivo",
              name: "preventivo-test-mutuo",
              component: () =>
                import("@/views/pratiche/PreventivoMutuoTest.vue"),
            },
            {
              path: "preventivo-test-prestito/:idAnagrafica/:idPreventivo",
              name: "preventivo-test-prestito",
              component: () =>
                import("@/views/pratiche/PreventivoPrestitoTest.vue"),
            },
            {
              path: "preventivo-test-cqs/:idAnagrafica/:idPreventivo/:idPratica/:tipoProdotto",
              name: "preventivo-test-cqs",
              component: () =>
                import("@/views/pratiche/PreventivoCQS&DelegaTest.vue"),
            },

            {
              path: "preventivo-test-delega/:idAnagrafica/:idPreventivo/:idPratica/:tipoProdotto",
              name: "preventivo-delega",
              component: () =>
                import("@/views/pratiche/PreventivoCQS&DelegaTest.vue"),
            },
            {
              path: "nuovo-mutuo",
              name: "nuovo-mutuo",
              component: () => import("@/views/pratiche/NuovoMutuo.vue"),
            },
            {
              path: "nuovo-prestito",
              name: "nuovo-prestito",
              component: () => import("@/views/pratiche/NuovoPrestito.vue"),
            },
            {
              path: "nuovo-prestito-finalizzato",
              name: "nuovo-prestito-finalizzato",
              component: () =>
                import("@/views/pratiche/NuovoPrestitoFinalizzato.vue"),
            },
            {
              path: "nuovo-leasing",
              name: "nuovo-leasing",
              component: () => import("@/views/pratiche/NuovoLeasing.vue"),
            },
            {
              path: "nuova-cqs",
              name: "nuova-cqs",
              component: () => import("@/views/pratiche/NuovaCQS.vue"),
            },
            {
              path: "nuova-delega",
              name: "nuova-delega",
              component: () => import("@/views/pratiche/NuovaDelega.vue"),
            },
            {
              path: "nuova-corporate",
              name: "nuova-corporate",
              component: () => import("@/views/pratiche/NuovaCorporate.vue"),
            },
            {
              path: "preventivi",
              name: "preventivi",
              component: () => import("@/views/pratiche/Preventivi.vue"),
            },
            {
              path: "bozze",
              name: "bozze",
              component: () => import("@/views/pratiche/ListaPratiche.vue"),
            },
            {
              path: "in-lavorazione",
              name: "in-lavorazione",
              component: () => import("@/views/pratiche/ListaPratiche.vue"),
            },
            {
              path: "erogate",
              name: "erogate",
              component: () => import("@/views/pratiche/ListaPratiche.vue"),
            },
            {
              path: "annullate",
              name: "annullate",
              component: () => import("@/views/pratiche/ListaPratiche.vue"),
            },
            {
              path: "archiviate",
              name: "archiviate",
              component: () => import("@/views/pratiche/ListaPratiche.vue"),
            },
            {
              path: "rinnovabili",
              name: "rinnovabili",
              component: () => import("@/views/pratiche/ListaPratiche.vue"),
            },
            {
              path: "corporate-in-lavorazione",
              name: "corporate-in-lavorazione",
              component: () => import("@/views/pratiche/ListaCorporate.vue"),
            },
            {
              path: "corporate-erogate",
              name: "corporate-erogate",
              component: () => import("@/views/pratiche/ListaCorporate.vue"),
            },
            {
              path: "corporate-annullate",
              name: "corporate-annullate",
              component: () => import("@/views/pratiche/ListaCorporate.vue"),
            },
            {
              path: "corporate-standby",
              name: "corporate-standby",
              component: () => import("@/views/pratiche/ListaCorporate.vue"),
            },
            {
              path: "corporate-ritirate",
              name: "corporate-ritirate",
              component: () => import("@/views/pratiche/ListaCorporate.vue"),
            },
            {
              path: "corporate-bozze",
              name: "corporate-bozze",
              component: () => import("@/views/pratiche/ListaCorporate.vue"),
            },
          ],
        },
        {
          path: "gestione",
          name: "gestione",
          children: [
            {
              path: "proforma",
              component: () => import("@/views/gestione/Proforma.vue"),
            },
            {
              path: "tipi-spesa",
              component: () => import("@/views/gestione/TipiSpesa.vue"),
            },
            {
              path: "tipi-incasso",
              component: () => import("@/views/gestione/TipiIncasso.vue"),
            },
            {
              path: "notizie",
              component: () => import("@/views/gestione/Notizie.vue"),
            },
            {
              path: "stati-compensi",
              component: () => import("@/views/gestione/StatiCompensi.vue"),
            },
            {
              path: "log-importazione",
              component: () => import("@/views/gestione/LogImportazione.vue"),
            },
            {
              path: "elenco-preventivi-salvati",
              component: () =>
                import("@/views/gestione/ElencoPreventiviSalvati.vue"),
            },
            {
              path: "regole-importazione",
              component: () =>
                import("@/views/gestione/RegoleDiImportazione.vue"),
            },
            {
              path: "campagne-marketing",
              component: () => import("@/views/gestione/CampagneMarketing.vue"),
            },
            {
              path: "modelli-mail",
              component: () => import("@/views/gestione/ModelliMail.vue"),
            },
            {
              path: "modelli-sms",
              component: () => import("@/views/gestione/ModelliSMS.vue"),
            },
            {
              path: "modelli-wa",
              component: () => import("@/views/gestione/ModelliWA.vue"),
            },
            {
              path: "stati-pratica",
              component: () => import("@/views/gestione/StatiPratica.vue"),
            },
            {
              path: "corsi-di-formazione",
              component: () => import("@/views/gestione/CorsiDiFormazione.vue"),
            },
            {
              path: "calendario-corsi-di-formazione",
              name: "CalendarioCorsiDiFormazione",
              component: () =>
                import("@/views/gestione/CalendarioCorsiDiFormazione.vue"),
            },
            {
              path: "dettaglio-corso/:idCorso",
              component: () =>
                import("@/views/gestione/DettaglioCorsoDiFormazione.vue"),
            },
            {
              path: "formazione-online",
              component: () => import("@/views/gestione/FormazioneOnline.vue"),
            },
            {
              path: "argomenti-ticket",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "utenti",
              component: () => import("@/views/gestione/Users.vue"),
            },
            {
              path: "utenti-listino",
              component: () => import("@/views/gestione/UsersLight.vue"),
            },
            {
              path: "utenti-banche",
              component: () => import("@/views/gestione/UsersBanche.vue"),
            },
            {
              path: "scripts",
              component: () => import("@/views/gestione/Scripts.vue"),
            },
            {
              path: "questionari",
              component: () => import("@/views/gestione/Questionari.vue"),
            },
            {
              path: "stati-anagrafica",
              name: "stati-anagrafica",
              component: () => import("@/views/gestione/StatiAnagrafica.vue"),
            },
            {
              path: "stati-evento",
              name: "stati-evento",
              component: () => import("@/views/gestione/StatiEvento.vue"),
            },
            {
              path: "forme-giuriche",
              name: "forme-giuriche",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "sesso",
              name: "sesso",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "stati-anagrafica",
              name: "stati-anagrafica",
              component: () => import("@/views/gestione/StatiAnagrafica.vue"),
            },
            {
              path: "stati-civili",
              name: "stati-civili",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "stati-giuridici",
              name: "stati-giuridici",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "stati-occupazionali",
              name: "stati-occupazionali",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "tipi-contatto",
              name: "tipi-contatto",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "tipi-documento",
              name: "tipi-documento",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "tipi-indirizzo",
              name: "tipi-indirizzo",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "nazioni",
              name: "nazioni",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "regioni",
              name: "regioni",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "provincie",
              name: "provincie",
              component: () => import("@/views/gestione/Provincie.vue"),
            },
            {
              path: "citta",
              name: "citta",
              component: () => import("@/views/gestione/Citta.vue"),
            },
            {
              path: "tipi-sede",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "sedi-azienda",
              component: () => import("@/views/gestione/SediAzienda.vue"),
            },
            {
              path: "dealer",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "livelli-login",
              component: () => import("@/views/gestione/LivelliLogin.vue"),
            },
            {
              path: "categorie-learning",
              component: () => import("@/views/gestione/CategorieLearning.vue"),
            },
            {
              path: "tipo-doc-pratiche",
              component: () =>
                import("@/views/gestione/TipiDocumentoPratiche.vue"),
            },
          ],
        },
        {
          path: "supporto",
          name: "supporto",
          children: [
            {
              path: "legale",
              name: "legale",
              component: () => import("@/views/supporto/Legale.vue"),
            },
            {
              path: "it",
              name: "it",
              component: () => import("@/views/supporto/IT.vue"),
            },
            {
              path: "pratiche",
              name: "supporto-pratiche",
              component: () => import("@/views/supporto/Pratiche.vue"),
            },
            {
              path: "amministrazione",
              name: "supporto-amministrazione",
              component: () => import("@/views/supporto/Amministrazione.vue"),
            },
          ],
        },
        {
          path: "plugins",
          name: "plugins",
          children: [
            {
              path: "lista-exp-anagrafiche",
              name: "lista-exp-anagrafiche",
              component: () =>
                import("@/views/plugins/ListaEsportazioniAnag.vue"),
            },
            {
              path: "modelli-exp-anagrafiche",
              name: "modelli-exp-anagrafiche",
              component: () =>
                import("@/views/plugins/ModelliEsportazioneAnagrafiche.vue"),
            },
            {
              path: "modello-exp-anagrafiche",
              name: "modello-exp-anagrafiche",
              component: () =>
                import("@/views/plugins/ModelloEsportazioneAnag.vue"),
            },
            {
              path: "calcolatore-estinzione",
              name: "CalcolatoreEstinzione",
              component: () =>
                import("@/views/plugins/CalcolatoreEstinzione.vue"),
            },
            {
              path: "censimento-istituti-credito",
              component: () =>
                import("@/views/plugins/CensimentoIstitutiDiCredito.vue"),
            },
            {
              path: "gestione-invii",
              component: () => import("@/views/plugins/GestioneInvii.vue"),
            },
            {
              path: "gestione-visitatori",
              component: () => import("@/views/plugins/GestioneVisitatori.vue"),
            },
            {
              path: "prenotazione-sale",
              component: () => import("@/views/plugins/PrenotazioneSale.vue"),
            },
            {
              path: "gestione-sale",
              component: () => import("@/views/gestione/GenericCrud.vue"),
            },
            {
              path: "data-drive",
              name: "DataDrive",
              component: () => import("@/views/plugins/DataDrive.vue"),
            },
            {
              path: "data-drive-mkt",
              component: () => import("@/views/plugins/DataDriveMkt.vue"),
            },
            {
              path: "inventario",
              component: () => import("@/views/plugins/Inventario.vue"),
            },
          ],
        },
        {
          path: "importatore",
          name: "importatore",
          children: [
            {
              path: "excel",
              component: () => import("@/views/importatore/Excel.vue"),
            },
            {
              path: "anagrafiche",
              component: () => import("@/views/importatore/Anagrafiche.vue"),
            },
            {
              path: "storico",
              component: () => import("@/views/importatore/Storico.vue"),
            },
          ],
        },

        {
          path: "/crm/:idAnagrafica",
          name: "crm",
          component: () => import("@/views/CRM/CrmLayout.vue"),
        },
        {
          path: "/recruitment-crm/:idAnagrafica",
          name: "recruitment-crm",
          component: () => import("@/views/CRM/RecruitmentCrmLayout.vue"),
        },
        {
          path: "/corporate-crm/:idAnagrafica",
          name: "corporate-crm",
          component: () => import("@/views/CRM/CorporateCrmLayout.vue"),
        },
        {
          path: "organigramma",
          name: "organigramma",
          component: () => import("@/views/Organigramma.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const DEFAULT_TITLE = "DataRete Cloud";
  const title = to.meta.title || DEFAULT_TITLE;
  if (title) {
    document.title = title;
  }

  if (
    to.name !== "Login" &&
    to.name !== "ChangePasswordRequest" &&
    to.name !== "ChangePasswordPerform" &&
    !store.getters.isAuthenticated
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
